/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { useEffect } from "react";
import { Heading, Flex, Box, Text } from "rebass";
import Layout from "../layouts/";
import Header from "../components/Header";
import Footer from "../components/Footer";
export default function Expertise() {
  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.backgroundElevation as string;
  const projectTextColor = theme?.colors?.text as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);
  return (
    <Layout>
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        <Flex flexWrap="wrap" alignItems="center" variant="containerPage">
          <Box width={[1 / 1]}>
            <Heading as="h3" variant="catHeading" mb={[2]}>
              Long story short
            </Heading>
            <Heading as="h1" variant="bigTitle" className="uppercase">
              Design Across <br />
              Disciplines
            </Heading>
          </Box>
          <Box
            order={[1, 2]}
            width={[1 / 1]}
            mb={[4, 6]}
            sx={{ position: "relative" }}
          >
          <Text as="p" maxWidth="40ch" mt={[4]}>
            Multi-disciplinary designer, occasional coder, OOUXer in the making (Object Oriented UX), assembler of stellar design systems. Devoted to advancing mobile first thinking, consistent UI, not just pretty but smart too (I’m of course talking about the interfaces).
          </Text>
          </Box>
        </Flex>
        <Footer />
      </main>
    </Layout>
  );
}
